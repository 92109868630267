import React from 'react'
import axios from 'axios'
import { navigate } from "gatsby"
import { HeroFormBtnArrow } from "../Icons"
import FormButton from "../Button/FormButton"

class HeroForm extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            source: '',
            page: '',
            form: '',
            name: '',            
            email: '',
            phone_no: '',
            zipcode: '',
            state: '',
            comment: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
		if (typeof window !== 'undefined') 
		{
			const searchParams = new URLSearchParams(window.location.search);
			if(searchParams.get("utm_source")!='' && searchParams.get("utm_source")!=null)
			{
				sessionStorage.setItem('utm_source', searchParams.get("utm_source"));
				sessionStorage.setItem('utm_medium', searchParams.get("utm_medium"));
				sessionStorage.setItem('utm_campaign', searchParams.get("utm_campaign"));
				sessionStorage.setItem('utm_term', searchParams.get("utm_term"));
				sessionStorage.setItem('utm_content', searchParams.get("utm_content"));
			}
		}
	}
    handleChange(event) {
        const {id, value} = event.target
        this.setState({
            [id]: value
        })
    }
    handleSubmit(event) {
        let utm_source_val = 'website';
		let utm_medium_val = '';
		let utm_campaign_val = '';
		let utm_term_val = '';
		let utm_content_val = '';
		if(sessionStorage.getItem('utm_source')!='' && sessionStorage.getItem('utm_source')!=null) 
		{
			utm_source_val = sessionStorage.getItem('utm_source');
			utm_medium_val = sessionStorage.getItem('utm_medium');
			utm_campaign_val = sessionStorage.getItem('utm_campaign');
			utm_term_val = sessionStorage.getItem('utm_term');
			utm_content_val = sessionStorage.getItem('utm_content');
		}

        event.preventDefault()
        const { location } = this.props
        const data = {
            source: location.origin,
            page: location.href,
            form: event.target.id,
            first_name: this.state.name,
            email: this.state.email,
            phone_no: this.state.phone_no,
            zipcode: this.state.zipcode,
            state: this.state.state,
            comment: this.state.comment,
            utm_medium: utm_medium_val,
			utm_term: utm_term_val,
			utm_campaign: utm_campaign_val,
			utm_content: utm_content_val,
			lead_source: utm_source_val,
            api_token: process.env.GATSBY_FORM_CRM_TOKEN,     
        }
        if (typeof window !== `undefined`) {
			document.getElementById("heroFormBtn").setAttribute("disabled", "");
		}
        //console.log(data, 'Form Name Test');
        axios({
          method: "post",
          url: "https://crm.senseicrm.com/api/create-lead-wordpress",
          data: data,
          headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
        }).then((res) => {
            if (typeof dataLayer !== 'undefined') {
                window.dataLayer.push({
                    'event': 'form_submission',
                    'formName': 'freequote_forms',
                    'enhanced_conversion_data': {
                        'email': this.state.email,
                        'phone_number': this.state.phone_no
                    }
                });
            }
            this.setState({ source: '', page: '', name: '', email: '', phone_no: '', zipcode: '', state: '', comment: ''});
            //console.log(res, 'Response');
            navigate("/thank-you-free-quote");
          }).catch((error) => {
              alert(error)
          });
    }
    render() {
        return(                           
            <form id="personalformbecomedealer" onSubmit={this.handleSubmit} className="grid">
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="text" id="name" className="form-control" onChange={this.handleChange} pattern="[A-Za-z\s]+$" placeholder="Name" aria-label="name" required/>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="email" id="email" placeholder='Email' className="form-control" onChange={this.handleChange} aria-label="email" required/>
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="tel" placeholder="Phone Number" id="phone_no" className="form-control" onChange={this.handleChange} pattern="[0-9]{10}" aria-label="phone number" required/>
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <input type="text" id="zipcode" className="form-control" onChange={this.handleChange} pattern="[0-9]+$" placeholder="Zip Code" aria-label="zip code" required/>
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <select id="state" className={this.props.state !=='' ?"form-control label":"form-control"} onChange={this.handleChange} aria-label="state">
                            <option value="">Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <textarea placeholder="Type Your Message Here ...." id="comment" className="form-control" onChange={this.handleChange} rows="3" aria-label="comment" required />                    
                    </div>                
                </div>
                <div className="grid-12">
                    <div className="form-action">
                        <FormButton id="heroFormBtn" icon={<HeroFormBtnArrow />} text="Submit" />
                    </div>
                </div>
            </form>                
	    )
    }
}

export default HeroForm