import React, { useState, Suspense } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from "../components/HomeHero"
// import VeteransDayPopup from "../components/VeteransDayPopup"
// import HowCanWeHelp from "../components/HowCanWeHelp"


const _ = require('lodash')

const ProductSection = React.lazy(() =>
  import("../sections/Product/ProductSection")
)
const DesignBuilding = React.lazy(() => import("../components/DesignBuilding"))
const CarportInstallSection = React.lazy(() =>
  import("../sections/Home/CarportInstallSection")
)
const FinancingProgram = React.lazy(() => import("../sections/Home/FinancingProgram"));
const ProcessSection = React.lazy(() => import("../components/Process"))
const WhyChooseSection = React.lazy(() => import("../components/WhyChoose"))
const ReviewsSection = React.lazy(() =>
  import("../sections/Home/ReviewsSection")
)
const ServiceAreaSection = React.lazy(() =>
  import("../sections/Home/ServiceAreaSection")
)
const RtoFinancingSection = React.lazy(() =>
  import("../components/RtoFinancing")
)
const KnowledgeHubSection = React.lazy(() =>
  import("../sections/Home/KnowledgeHubSection")
)
const QuotePopup = React.lazy(() => import("../components/QuotePopup"))

const IndexPage = ({ data, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  // const [isVisibleSale, setSaleVisible] = useState(false)
  // useEffect(() => {
  //   setTimeout(() => setSaleVisible(true), 3000);
  // }, []);

  // if (typeof window !== `undefined`) {
  //   if (isVisibleSale === true) {
  //     document.body.classList.add("modal-open")
  //   } else {
  //     document.body.classList.remove("modal-open")
  //   }
  // }
  const pageData = data.contentfulHomePage
  const heroBannerData = data.contentfulTestPage
  const heroData = {
    label: heroBannerData.heroLabel,
    title: heroBannerData.heroTitle,
    features: heroBannerData.heroFeatures,
  }
  const productsData = data.allContentfulProduct

  let allSubCategories = []
  data.allContentfulProduct.edges.forEach(function(item) {
    item.node.productSubCategories.forEach(function(node) {
      allSubCategories.push(node.name)  
    })
  })
  
  const allProductApplications = data.allContentfulProductApplication.edges
  const allRoofStyles = data.allContentfulRoofStyle.edges
  const allStates = data.allContentfulState.edges
  const installSectionData = {
    label: pageData.chooseSection.label,
    title: pageData.chooseSection.title,
    description: pageData.chooseSection.description,
    image: pageData.chooseSection.backgroundImage,
  }
  const processSectionData = {
    label: pageData.processSectionLabel,
    title: pageData.processSectionTitle,
    processes: pageData.processFeatures,
  }
  const serviceAreaData = {
    title: pageData.serviceAreaTitle,
    states: allStates,
    products: productsData.edges,
  }
  const whyChooseSectionData = {
    label: pageData.whyChooseSectionLabel,
    title: pageData.whyChooseSectionTitle,
    tabData: pageData.whyChooseSectionFeatures,
  }
  const financialData = {
    label: pageData.financingSectionLabel,
    sectionData: pageData.financialSection,
  }
  const knowledgeHubData = {
    label: pageData.knowledgeHubLabel,
    title: pageData.knowledgeHubTitle,
    background: pageData.knowledgeHubImage,
    tabData: pageData.knowledgeHubFeatures,
  }
  const reviewsData = {
    label: pageData.reviews.label,
    title: pageData.reviews.title,
    buttonText: pageData.reviews.buttonText,
    buttonLink: "/reviews",
    image: pageData.reviews.backgroundImage,
    reviews: data.allContentfulReview.edges,
  }

  const isSSR = typeof window === "undefined"
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <HomeHero data={heroData} location={location} />
      {!isSSR && (
        <Suspense
          fallback={
            <div className="loading">
              <i className="hm-spinner"></i>
            </div>
          }
        >
          <ProductSection
            sectionShadow={pageData.exploreSectionLabel}
            sectionTitle={pageData.exploreSectionTitle}
            data={productsData}
            buildingType={_.uniq(allSubCategories)}
            buildingApplication={allProductApplications}
            allRoofStyles={allRoofStyles}
            openQuoteModal={() => setQuoteVisible(true)}
          />
          <DesignBuilding />
          <FinancingProgram />
          <CarportInstallSection data={installSectionData} />
          <ProcessSection data={processSectionData} />
          <WhyChooseSection data={whyChooseSectionData} />
          <ReviewsSection data={reviewsData} />
          <ServiceAreaSection data={serviceAreaData} />
          <RtoFinancingSection data={financialData} />
          <KnowledgeHubSection data={knowledgeHubData} />
          <QuotePopup
            isVisible={isVisibleQuote}
            location={location}
            onClose={() => setQuoteVisible(false)}
          />
        </Suspense>
      )}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulHomePage {
      metaTitle
      metaDescription
      heroLabel
      heroTitle
      heroFeatures {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonLink
        backgroundImage {
          title
          gatsbyImageData
        }
        image {
          title
          gatsbyImageData
        }
      }
      exploreSectionLabel
      exploreSectionTitle
      chooseSection {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          gatsbyImageData
        }
      }
      processSectionLabel
      processSectionTitle
      processFeatures {
        label
        title
        description {
          description
        }
        image {
          title
          gatsbyImageData
        }
        buttonText
        buttonLink
      }
      whyChooseSectionLabel
      whyChooseSectionTitle
      whyChooseSectionFeatures {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
      }
      reviews {
        label
        title
        buttonText
        backgroundImage {
          title
          gatsbyImageData
        }
      }
      serviceAreaTitle
      financingSectionLabel
      financialSection {
        title
        tab
        benefits
        subtitle
        description
      }
      knowledgeHubLabel
      knowledgeHubTitle
      knowledgeHubImage {
        title
        gatsbyImageData
      }
      knowledgeHubFeatures {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
        buttonText
        buttonLink
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData(quality: 100)
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulProductApplication {
      edges {
        node {
          name
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          abbreviation
        }
      }
    }
    allContentfulReview {
      edges {
        node {
          reviewText {
            childMarkdownRemark {
              html
            }
          }
          customerName
          state {
            name
            abbreviation
            url
            flag {
              title
              gatsbyImageData
            }
          }
          linkedProduct {
            url
            productName
            productImages {
              title
              gatsbyImageData
            }
            productCategory {
              name
              url
            }
          }
        }
      }
    }
    contentfulTestPage {
      heroLabel
      heroTitle
      heroFeatures {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonLink
        image {
          title
          gatsbyImageData
        }
      }
    }
  }
`
